<div class="flex justify-center flex-col ">

  <div class="flex justify-end close-modal-button-pos">
    <button type="button" class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="closeModal()">
      <p class="bold mb-0">Close</p>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="admin-modal-body">
    <h1 class="text-center text-primary mb-xl sm:mb-2xl mt-8">Add Trainings</h1>
    <div [formGroup]="trainingFilterForm" class="flex flex-wrap justify-center lg:gap-5 mb-5">
      <div class="w-full lg:w-1/4">
        <mat-form-field class="w-full">
          <mat-label>Type</mat-label>
          <mat-select formControlName="training" panelClass="mat-select-mt3">
            <mat-option *ngFor="let training of lookupsStore?.trainingsSignal()" [value]="training.value">{{ training.label
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="w-full lg:w-1/4">
        <mat-form-field class="w-full">
          <mat-label>State</mat-label>
          <mat-select formControlName="state" panelClass="mat-select-mt3">
            <mat-option *ngFor="let state of lookupsStore?.statesSignal()" [value]="state.value">{{ state.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="trainingCoursesFound; else noneFound">
      <form [formGroup]="selectCourseForm">
        <div
          class="grid grid-rows-columns lg:ml-auto lg:mr-auto justify-center gap-8 mb-2xl">
          <app-choose-course [item]="course" *ngFor="let course of availableCoursesFiltered"
            class="w-full h-full transform transition hover:scale-110"></app-choose-course>
        </div>
        <div class="flex justify-center text-center mb-16 md:mb-32">
          <button class="flex btn-primary medium" [disabled]="disableAddTrainingBtn"
            (click)="addCoursesOrgAdmin()">
            <mat-spinner class="mr-5" *ngIf="addTrainingLoading" [diameter]="20"></mat-spinner>Add Trainings
          </button>
        </div>
      </form>
    </div>
    <ng-template #noneFound>
      <p *ngIf="!showTypeAndState" class="text-center font-semibold">
        No trainings found that match your search criteria.
        <br>
        Use the filters above to modify your search or contact your
        organization's administrator to assign trainings.
      </p>
      <p *ngIf="showTypeAndState" class="text-center font-semibold">
        No trainings found that match your search criteria. Use the filters above to modify your search.
      </p>
    </ng-template>
  </div>



</div>