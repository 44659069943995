<div class="flex flex-col h-full p-3">
    <div class="text-right justify-end close-modal-button-pos">
      <button type="button" class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="closeModal()">
        <p class="bold mb-0">Close</p>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  
    <div class="admin-modal-body">
      <h1 class="learner text-primary text-center w-full font-bold">
       {{ action +  ' Banner Message' }}
      </h1>

      <form [formGroup]="form" class="mt-8 notification-form">
        <div>
          <div class="w-full">
            <h3 class="learner">Content</h3>
          </div>
  
          <div class="w-full flex lg:gap-8">
            <div class="w-full lg:w-1/2">
              <mat-form-field class="w-full">
                <input placeholder="Title" matInput formControlName="title" maxlength="100"/>
                <mat-error *ngIf="form.controls['title'].hasError('required') && form.controls['title'].touched"
                >Required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="w-full lg:w-1/2">
              <mat-form-field class="datePicker-2 w-full">
                <mat-label>Maintenance Date</mat-label>
                <input matInput formControlName="maintenanceDate" [matDatepicker]="picker3" />
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3 disabled="false"></mat-datepicker>
                <mat-error *ngIf="form.controls['maintenanceDate'].hasError('required') && form.controls['maintenanceDate'].touched"
                >Required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
  
          <div class="w-full flex lg:gap-8 mt-4">
            <div class="w-full">
              <mat-form-field class="w-full">
                <textarea
                  placeholder="Message"
                  matInput
                  formControlName="message"
                  maxlength="500"
                  style="height: 90px"
                ></textarea>
                <mat-error *ngIf="form.controls['message'].hasError('required') && form.controls['message'].touched"
                >Required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
  
          <div class="w-full mt-8">
            <h3 class="learner">Primary CTA</h3>
          </div>
          <div class="w-full flex lg:gap-8 mt-4">
            <div class="w-full lg:w-1/2">
              <mat-form-field class="w-full">
                <input placeholder="Button Text" matInput formControlName="buttonText" maxlength="50"/>
                <mat-error *ngIf="form.controls['buttonText'].hasError('required') && form.controls['buttonText'].touched"
                >Required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="w-full lg:w-1/2">
              <mat-form-field class="w-full">
                <input placeholder="Button URL" matInput formControlName="buttonLink" />
                <mat-error *ngIf="form.controls['buttonLink'].hasError('required') && form.controls['buttonLink'].touched"
                >Required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
  
  
          <div class="w-full mt-8">
            <h3 class="learner">Display Criteria</h3>
          </div>
          <div class="w-full flex lg:gap-8 mt-4">
            <div class="w-full lg:w-1/2">
              <mat-form-field class="datePicker-2 w-full">
                <mat-label>Start Date</mat-label>
                <input matInput formControlName="displayStartDate" [matDatepicker]="picker1" />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                <mat-error *ngIf="form.controls['displayStartDate'].hasError('required') && form.controls['displayStartDate'].touched"
                  >Required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="w-full lg:w-1/2">
              <mat-form-field class="datePicker-2 w-full">
                <mat-label>End Date</mat-label>
                <input matInput formControlName="displayEndDate" [matDatepicker]="picker2" />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 disabled="false"></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="w-full flex lg:gap-8 justify-center mt-8">
          <div class="w-full text-center lg:w-1/2">
            <button (click)="onSubmit()" class="btn-primary large" type="submit" [disabled]="disableFlag"> {{ action === 'Edit' ? 'Edit App' : 'Add' }} Message</button>
          </div>
        </div>
      </form>
    </div>
</div>


