<div >
  <div class="flex justify-end close-modal-button-pos">
    <button type="button" class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="close()">
      <p class="bold mb-0">Close</p>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="admin-modal-body">
    <h1 class="learner text-primary text-center w-full font-bold">
      Paid Product
    </h1>
    <div [formGroup]="form">
      <div class="mt-3">
        <mat-form-field class="w-full">
          <mat-label>LearningPath</mat-label>
          <mat-select formControlName="learningPath" panelClass="mat-select-mt3">
            <mat-option *ngFor="let path of lookupsStore.learningPathSignal()" [value]="path.value">
              {{ path.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="form.controls['learningPath']?.hasError('required') && form.controls['learningPath']?.touched">Required</mat-error>
        </mat-form-field>
      </div>
      <div class="mt-3">
        <mat-form-field class="w-full">
          <mat-label>Stripe Price Id</mat-label>
          <input
            matInput
            type="text"
            formControlName="stripePriceId"
          />
          <mat-error *ngIf="form.controls['stripePriceId']?.hasError('required') && form.controls['stripePriceId']?.touched"
            >Required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="w-full flex">
        <div class="mt-3 w-1/3 pr-4">
          <mat-form-field class="datePicker-2 w-full">
            <mat-label>Start Date</mat-label>
            <input matInput formControlName="startDate" [matDatepicker]="picker1" />
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1 disabled="false"></mat-datepicker>
            <mat-error *ngIf="form.controls['startDate']?.hasError('required') && form.controls['startDate']?.touched"
              >Required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="mt-3 w-1/3 pr-4">
          <mat-form-field class="datePicker-2 w-full">
            <mat-label>End Date</mat-label>
            <input matInput formControlName="endDate" [matDatepicker]="picker2" />
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 disabled="false"></mat-datepicker>
            <mat-error *ngIf="form.controls['endDate']?.hasError('required') && form.controls['endDate']?.touched"
              >Required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="mt-3 w-1/3">
          <mat-form-field class="w-full">
            <mat-label>price</mat-label>
            <input
              matInput
              type="text"
              formControlName="price"
            />
            <mat-error *ngIf="form.controls['price']?.hasError('required') && form.controls['price']?.touched"
              >Required
            </mat-error>
            <mat-error *ngIf="form.controls['price']?.hasError('pattern') && form.controls['price']?.touched"
              >Invalid Price
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <mat-dialog-actions class="justify-center">
      <div class="pb-6">
        <button   *ngxPermissionsOnly="['courses:edit']" class="btn btn-primary large" type="submit" [disabled]="disableButton" (click)="createProduct()">
          <ng-container>Submit</ng-container>
        </button>
      </div>
    </mat-dialog-actions>
  </div>

  
 
</div>