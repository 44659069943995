<div class="flex flex-col h-full p-3">
  <div class="text-right justify-end close-modal-button-pos">
    <button type="button" class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="closeModal()">
      <p class="bold mb-0">Close</p>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="admin-modal-body">
    <div class="flex justify-center w-full">
      <div class="w-2/3">
       <h1 class="learner text-primary text-center w-full font-bold">
         {{ data.action + ' Association' }}
       </h1>
       <p class="text-center p-4 w-full text-primary">{{ pTagContent }}</p>
       <form [formGroup]="form">
         <mat-form-field class="w-full p-2">
           <input type="text" placeholder="Associated Entity" matInput formControlName="associatedEntity" [matAutocomplete]="auto1" required="true" />
           <mat-error *ngIf="form.controls['associatedEntity'].hasError('required') && form.controls['associatedEntity'].touched"
           >Required
           </mat-error>
           <mat-autocomplete
             #auto1="matAutocomplete"
             [displayWith]="displayFn"
           >
             <mat-option *ngFor="let option of EntityNamesData" [value]="option">
               {{ option?.name }}
             </mat-option>
           </mat-autocomplete>
         </mat-form-field>
         <mat-form-field class="w-full p-2">
           <mat-label>Role</mat-label>
           <mat-select formControlName="roleGuids" multiple="true" panelClass="mat-select-mt3">
             <mat-option *ngFor="let role of this.lookupsStore.orgRolesSignal()" [value]="role.value"
               >{{ role.name }}
             </mat-option>
           </mat-select>
         </mat-form-field>
         <mat-form-field class="w-full p-2" *ngIf="!seenForAddAssociation">
           <mat-label>Status</mat-label>
           <mat-select formControlName="statusId" panelClass="mat-select-mt3">
             <mat-option *ngFor="let status of this.lookupsStore.entityStatusSignal()" [value]="status.value"
               >{{ status.name }}
             </mat-option>
           </mat-select>
         </mat-form-field>
         <mat-form-field class="w-full p-2" *ngIf="!seenForAddAssociation">
           <mat-label>Group</mat-label>
           <mat-select formControlName="groupIds" multiple="true" panelClass="mat-select-mt3">
             <mat-option *ngFor="let group of groupData" [value]="group.value"
               >{{ group.name }}
             </mat-option>
           </mat-select>
         </mat-form-field>
         <mat-form-field class="w-full p-2" *ngIf="seenForAddAssociation">
           <input placeholder="Notes" matInput formControlName="notes" />
           <mat-error *ngIf="form.controls['notes'].hasError('required') && form.controls['notes'].touched"
           >Required
           </mat-error>
           <mat-error *ngIf="form.controls['notes'].hasError('maxlength') && form.controls['notes'].touched"
           >Max 200 Characters
           </mat-error>
         </mat-form-field>
       </form>
       <div class="flex justify-center p-2">
         <button class="btn-primary large" type="submit" [disabled]="disableFlag" (click)="onSubmit()">
           <ng-container>{{ data.action + ' Association' }}</ng-container>
         </button>
       </div>
     </div> 
   </div>
 
  </div>
 
</div>
