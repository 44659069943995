<div class="flex justify-center flex-col">
  <div class="flex justify-end close-modal-button-pos">
    <button type="button"  class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="close()">
      <p class="bold mb-0">Close</p>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="admin-modal-body">
     
  <div class="px-12">
    <div class="text-center px-5 py-3">
      <h2 class="font-bold mb-5">Status</h2>
    </div>
    <div class="pb-8">
      <p class="font-medium text-[14px] text-center">Assign status within this {{data.entityType}} to this user.</p>
    </div>
    <div [formGroup]="statusForm">
      <mat-form-field class="w-full">
        <mat-label>Status</mat-label>
        <mat-select formControlName="status" panelClass="mat-select-mt3">
          <mat-option *ngFor="let status of lookupsStore?.entityStatusSignal()" [value]="status?.label"
            >{{ status.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="text-center px-5 py-3">
      <h2 class="font-bold mb-5 learner">Assign Role</h2>
    </div>
    <div class="pb-6">
      <p class="font-medium text-[14px] text-center">Assign roles within this {{data.entityType}} to this user.</p>
    </div>
    <div [formGroup]="roleForm" class="flex flex-col items-center pb-10">
      <div>
        <div class="flex items-center mb-3 font-bold">
          <mat-checkbox (change)="learnerRoleButton()" formControlName="learner">Learner</mat-checkbox>
        </div>
        <div class="flex items-center mb-3 font-bold" *ngIf="data?.entityType === 'Organization'">
          <mat-checkbox formControlName="entityAdmin"> Org. Admin</mat-checkbox>
        </div>
        <div class="flex items-center mb-3 font-bold" *ngIf="data?.entityType === 'Cooperative'">
          <mat-checkbox (change)="coopAdminRoleButton()" formControlName="coopAdmin"> Coop. Admin</mat-checkbox>
        </div>
      </div>
      <div>
        <mat-error *ngIf="validateForm"> Required </mat-error>
      </div>
    </div>

    <div class="pt-8 mb-4 flex justify-center">
      <button  *ngxPermissionsOnly="['orgs-user-associations:edit']" class="btn-primary medium" type="submit" (click)="submit()">
        Assign Status/Roles
      </button>
    </div>
  </div>
  </div>

 
</div>
