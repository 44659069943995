<div class="flex justify-center flex-col p-3">
  <div class="flex justify-end close-modal-button-pos">
    <button type="button"  class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="close()">
      <p class="bold mb-0">Close</p>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="admin-modal-body">
    <div class="px-10">
      <div class="text-center mb-10">
        <h2 class="font-bold">
          Confirm Email
        </h2>
      </div>
      <div class="pb-6 text-center">
        <p class="font-medium text-[14px]">
          Are you sure you want to confirm the email<br> <span class="primary-text font-bold">{{ data?.email }}</span>
        </p>
      </div>
      <div class="pt-8 mb-4 flex justify-center">
        <button  *ngxPermissionsOnly="['users:edit']"
          class="btn-primary medium"
          type="button"
          (click)="submitButton(data?.buttonAction)"
        >
          Confirm Email
        </button>
      </div>
    </div>
  </div>
</div>
