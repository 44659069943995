<div class="flex justify-center flex-col">
  <div class="flex justify-end close-modal-button-pos">
    <button type="button"  class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="close()">
      <p class="bold mb-0">Close</p>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="admin-modal-body">
    <div class="px-10">
      <div class="text-center mb-10">
        <h2 class="font-bold">
          {{
            data?.buttonAction === 'userall-confirmEmail'
              ? 'Confirm Email'
              : data?.buttonAction === 'userInvite-resent'
              ? 'Resend Invitation'
              : data?.buttonAction === 'userInvite-cancel'
              ? 'Cancel Invitation'
              : ''
          }}
        </h2>
      </div>
      <div class="pb-6 text-center">
        <p class="font-medium text-[14px]" *ngIf="data?.buttonAction === 'userall-confirmEmail'">
          Are you sure you want to confirm the email <strong>{{ data?.items?.email }}?</strong>
        </p>
        <p class="font-medium text-[14px]" *ngIf="data?.buttonAction === 'userInvite-resent'">
          Learner was originally invited to <strong>{{ data?.items.organizationName | titlecase }}</strong> on
          {{ data?.items?.dateSent | date : 'MM/dd/yy' }}. To resend the invitation email to
          <strong>{{ data?.items?.email }}</strong
          >, click the Resend Invite button.
        </p>
        <p class="font-medium text-[14px]" *ngIf="data?.buttonAction === 'userInvite-cancel'">
          Learner was originally invited to <strong>{{ data?.items.organizationName | titlecase }}</strong> on
          {{ data?.items?.dateSent | date : 'MM/dd/yy' }}. To cancel this invitation (and disable link in previously sent emails), click the Cancel
          Invite button.
        </p>
      </div>
      <div class="pt-8 mb-4 flex justify-center">
        <button *ngxPermissionsOnly="['orgs-user-invites:edit']"
          class="btn-primary medium"
          type="button"
          (click)="submitButton(data?.buttonAction)"
        >
          {{
            data?.buttonAction === 'userall-confirmEmail'
              ? 'Confirm Email'
              : data?.buttonAction === 'userInvite-resent'
              ? 'Resend Invite'
              : data?.buttonAction === 'userInvite-cancel'
              ? 'Cancel Invite'
              : ''
          }}
        </button>
      </div>
    </div>
  </div>


  
</div>
