<div class="flex justify-center flex-col p-3 text-center">

  <div class="flex justify-end close-modal-button-pos">
    <button type="button" class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="closeModal()">
      <p class="bold mb-0">Close</p>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="admin-modal-body">
      
  <h1>
    {{
    data?.buttonAction === 'add-training'
    ? 'Add Training'
    : data?.buttonAction === 'archive-training'
    ? 'Archive Training'
    : ''
    }}
  </h1>
  <p *ngIf="data?.buttonAction === 'add-training'">
    Adding <strong>{{ data?.course.name }}</strong> will add to the available trainings in your organization. Be
    sure to assign the training to any applicable groups directly to learners.
  </p>
  <p *ngIf="data?.buttonAction === 'archive-training'">
    Archiving <strong>{{ data?.course.name }}</strong> will remove from the available trainings in your
    organization. Any learners that have previously added this training will retain their Training History and your
    reports will continue to display metrics associated with this training.
  </p>
  <p>
    Click the
    {{
    data?.buttonAction === 'add-training'
    ? 'Add Training'
    : data?.buttonAction === 'archive-training'
    ? 'Archive Training'
    : ''
    }}
    button below to proceed
  </p>
  <div class="pt-8 mb-4 flex justify-center">
    <button *ngxPermissionsOnly="['orgs-info:edit']" class="btn-primary medium" (click)="submitButton()" [disabled]="disableFlag">
      {{
      data?.buttonAction === 'add-training'
      ? 'Add Training'
      : data?.buttonAction === 'archive-training'
      ? 'Archive Training'
      : ''
      }}
    </button>
  </div>
  </div>


</div>