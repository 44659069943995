<div>
    <div class="flex justify-end close-modal-button-pos">
      <button type="button" class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="closeModel()">
        <p class="bold mb-0">Close</p>
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="admin-modal-body">
      <h1 class="learner text-primary text-center w-full font-bold">
        Invite User to Organization
      </h1>
      <mat-dialog-content class="text-center">
        <div class="w-full">
          <form [formGroup]="invitesForm">
            <p class="w-full">
              Select an organization and enter user's email to send an invitation email to the user on behalf of the organization.
            </p>
            <div class="flex flex-wrap mt-5">
              
              <div class="w-full">
                <mat-form-field class="w-full">
                  <input type="text" placeholder="Org. Name" matInput formControlName="orgName" [matAutocomplete]="auto1" required="true" />
                  <mat-autocomplete
                    #auto1="matAutocomplete"
                    [displayWith]="displayFn"
                  >
                    <mat-option *ngFor="let option of orgNamesData" [value]="option">
                      {{ option?.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
  
              <div class="w-full">
                <mat-form-field class="w-full">
                  <input type="email" placeholder="Learner Email" matInput formControlName="userEmail" ngModel email required="true" />
                </mat-form-field>
              </div>
              
              <div class="w-full">
                <mat-form-field class="w-full">
                  <mat-label>Group</mat-label>
                  <mat-select formControlName="group" multiple="true" panelClass="mat-select-mt3">
                    <mat-option *ngFor="let group of groupData" [value]="group.value"
                      >{{ group.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
  
            </div>
          </form>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions class="justify-center">
        <div>
          <button *ngxPermissionsOnly="['users:edit']" class="btn-primary large" type="submit" [disabled]="disableFlag" (click)="onSubmitUsers()">
            <ng-container>Invite User</ng-container>
          </button>
        </div>
      </mat-dialog-actions>
    </div>


  
  </div>
  