<div class="flex flex-col h-full p-3">
  <div class="text-right justify-end close-modal-button-pos">
    <button type="button" class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="closeModal()">
      <p class="bold mb-0">Close</p>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="admin-modal-body">
    <h1 class="learner text-primary text-center w-full font-bold">
      {{ data.action + ' Course Version' }}
    </h1>
    <div class="w-full overflow-y-auto">
      <form [formGroup]="form">
        <div class="flex flex-wrap mt-5">
          <!-- course -->
          <ng-container formGroupName="course">
            <div class="w-full">
              <h2 class="mb-0 pl-2 learner">Course Details</h2>
            </div>
            <div class="w-1/3">
              <mat-form-field class="w-full p-2">
                <mat-label>Training</mat-label>
                <mat-select formControlName="trainingId" panelClass="mat-select-mt3">
                  <mat-option *ngFor="let training of lookupsStore.trainingsSignal()" [value]="training.value"
                    >{{ training.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="w-1/3">
              <mat-form-field class="w-full p-2">
                <input placeholder="Learning Path Name" matInput formControlName="learningPathName" />
              </mat-form-field>
            </div>
  
            <div class="w-1/3">
              <mat-form-field class="w-full p-2">
                <input placeholder="Course Name" matInput formControlName="courseName" />
              </mat-form-field>
            </div>
  
            <div class="w-full">
              <mat-form-field class="w-full p-2">
                <mat-label>States Available</mat-label>
                <mat-select formControlName="courseStates" multiple="true" panelClass="mat-select-mt3">
                  <mat-option *ngFor="let state of lookupsStore.statesSignal()" [value]="state.value"
                    >{{ state.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
  
          <!-- course version -->
          <div class="w-full">
            <h2 class="mb-0 pl-2 learner">Course Version</h2>
          </div>
          <ng-container formGroupName="courseVersion">
            <div class="w-1/3">
              <mat-form-field class="w-full p-2">
                <input placeholder="Major Version" matInput type="number" formControlName="courseVersionMajor" />
              </mat-form-field>
            </div>
            <div class="w-1/3">
              <mat-form-field class="w-full p-2">
                <input placeholder="Minor Version" matInput type="number" formControlName="courseVersionMinor" />
              </mat-form-field>
            </div>
            <div class="w-1/3">
              <mat-form-field class="w-full p-2">
                <mat-label>Language</mat-label>
                <mat-select formControlName="languageId" panelClass="mat-select-mt3">
                  <mat-option [value]="1">English</mat-option>
                  <mat-option [value]="2">Spanish</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="w-full mt-5">
              <h2 class="mb-0 pl-2 learner">Assign Modules</h2>
            </div>
            <div class="flex flex-col w-3/4 pl-2" formArrayName="modules">
              <div class="border-solid border-b-1" *ngFor="let moduleControl of modulesArray.controls; let i = index">
                <ng-container *ngIf="!moduleControl?.value?.isInList; else info" [formGroupName]="i">
                  <div class="flex justify-between items-center">
                    <div class="w-full">
                      <mat-form-field class="w-full p-2">
                        <mat-label>Select Module</mat-label>
                        <mat-select
                          formControlName="moduleId"
                          panelClass="mat-select-mt3"
                          (selectionChange)="onModuleSelect($event, i)"
                        >
                          <mat-option *ngFor="let module of lookupsStore.modulesSignal()" [value]="module.value"
                            >{{ module.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="flex items-center" *ngIf="enableForm">
                      <button  *ngxPermissionsOnly="['courses:edit']" type="button" class="primary-text cursor-pointer opacity-100" (click)="deleteModule(i)">
                        <mat-icon> close</mat-icon>
                      </button>
                    </div>
                  </div>
                </ng-container>
                <ng-template #info>
                  <div
                    class="flex justify-between items-center p-3"
                    [ngClass]="{ 'border-solid border-green-300 border-1': moduleControl?.value?.isNewModule }"
                  >
                    <h3 class="mb-0 learner">{{ i + 1 }} {{ moduleControl?.value?.moduleName }}</h3>
                    <p class="mb-0">
                      {{ moduleControl?.value?.scormShortName }} - {{ moduleControl?.value?.scormType }} -
                      {{ moduleControl?.value?.scormDuration }} mins.
                    </p>
                    <div class="flex items-center" *ngIf="enableForm">
                      <button   *ngxPermissionsOnly="['courses:edit']" type="button" class="primary-text cursor-pointer opacity-100" (click)="deleteModule(i)">
                        <mat-icon> close</mat-icon>
                      </button>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-container>
          <div class="w-full mt-5" *ngIf="enableForm">
            <button  *ngxPermissionsOnly="['courses:edit']" 
              type="button"
              class="primary-text flex justify-start items-center cursor-pointer opacity-100 w-full"
              (click)="addModule()"
            >
              <mat-icon>add</mat-icon>
              <span>Add Module</span>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div *ngFor="let error of errors" class="my-4">
      <p class="text-red-400">{{ error.field }}: {{ error.message }}</p>
    </div>
    <div class="mt-auto pb-3 flex justify-center flex-col items-center">
      <form [formGroup]="form">
        <ng-container formGroupName="courseVersion">
          <div class="w-full items-center">
            <div class="flex flex-col items-center pb-8">
              <span class="pb-2 text-primary">Course Version Published</span>
              <mat-slide-toggle formControlName="published">
              </mat-slide-toggle>
            </div>
          </div>
        </ng-container>
      </form>
      <button   *ngxPermissionsOnly="['courses:edit']"  class="btn-primary large" type="submit" [disabled]="disableFlag || !enableForm" (click)="onSubmit()">
        <ng-container>{{ data.action + ' Course Version' }}</ng-container>
      </button>
    </div>
  </div>


</div>