<div class="flex flex-col h-full p-3">
  <div class="text-right justify-end close-modal-button-pos">
    <button type="button" class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="closeModal()">
      <p class="bold mb-0">Close</p>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="admin-modal-body">
    <h1 class="learner text-primary text-center w-full font-bold">{{modalTitle}}</h1>
    <div class="w-full overflow-y-auto">
      <form [formGroup]="moduleForm">
        <div class="flex flex-wrap w-full">
          <div class="w-full">
            <mat-form-field class="w-full p-2">
              <mat-label>Module Template</mat-label>
              <mat-select
                formControlName="moduleId"
                panelClass="mat-select-mt3"
                (selectionChange)="onModuleSelect($event)"
              >
                <mat-option [value]="0">Add New Module</mat-option>
                <mat-option *ngFor="let module of lookupsStore.modulesSignal()" [value]="module.value"
                  >{{ module.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="w-full">
            <h2 class="mb-0 pl-2 learner">Module</h2>
          </div>
          <div class="w-full">
            <mat-form-field class="w-full p-2">
              <input placeholder="Module Name" matInput formControlName="moduleName" />
            </mat-form-field>
          </div>
          <div class="w-full">
            <h2 class="mb-0 pl-2 learner">Scorm</h2>
          </div>
          <div class="w-1/2">
            <mat-form-field class="w-full p-2">
              <input placeholder="Scorm Name" matInput formControlName="scormFullName" />
            </mat-form-field>
          </div>
          <div class="w-1/2">
            <mat-form-field class="w-full p-2">
              <input placeholder="Package Name" matInput formControlName="scormShortName" />
            </mat-form-field>
          </div>
          <div class="w-full">
            <mat-form-field class="w-full p-2">
              <textarea
                placeholder="Module Description"
                matInput
                formControlName="scormDescription"
                style="height: 90px"
              ></textarea>
            </mat-form-field>
          </div>
          <div class="w-1/3">
            <mat-form-field class="w-full p-2">
              <mat-label>Scorm Type</mat-label>
              <mat-select formControlName="scormTypeId" panelClass="mat-select-mt3">
                <mat-option *ngFor="let scormType of lookupsStore.scormTypesSignal()" [value]="scormType.value"
                  >{{ scormType.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="w-1/3">
            <mat-form-field class="w-full p-2">
              <mat-label>Language</mat-label>
              <mat-select formControlName="language" panelClass="mat-select-mt3">
                <mat-option value="English">English</mat-option>
                <mat-option value="Spanish">Spanish</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="w-1/3">
            <mat-form-field class="w-full p-2">
              <input placeholder="Scorm Duration (Minutes)" matInput type="number" formControlName="scormDuration" />
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    <div class="mt-auto pb-3 flex justify-center">
      <button  *ngxPermissionsOnly="['courses:edit']"  class="btn-primary large" type="submit" [disabled]="disableFlag" (click)="onSubmit()">
        <ng-container>{{ modalTitle == "Add Module" ? "Submit" : "Update"}}</ng-container>
      </button>
    </div>
  </div>


 
</div>
